import { useState, useEffect } from 'react';
import './Landing.css';
import { RouteComponentProps } from 'react-router-dom';
import Logo from '../img/nfc.png';
import SatoriLogo from '../img/satori.jpg';
import { useQRCode } from 'next-qrcode';

import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpLoadPromise = FingerprintJS.load()


declare global {
	interface Window {
		grecaptcha: ReCaptchaInstance
	}
}

interface ReCaptchaInstance {
	ready: (cb: () => any) => void
	execute: (siteKey: string, options: ReCaptchaExecuteOptions) => Promise<string>
	render: (id: string, options: ReCaptchaRenderOptions) => any
}

interface ReCaptchaExecuteOptions {
	action: string
}

interface ReCaptchaRenderOptions {
	sitekey: string
	size: 'invisible'
}

interface ReCaptchaResponse {
	success: boolean
}

type Social = 'twitter' | 'facebook' | 'instagram'

interface Requirements {
	location: boolean
	recaptcha?: boolean
	socials?: Social[]
}

interface Coords {
	latitude: number
	longitude: number
}

interface LocationInfo {
	available: boolean
	checked: boolean
	denied: boolean
	softAgreed: boolean
	coords: Coords | null
}

const API_HOST = process.env.REACT_APP_API_HOST
const SITE_KEY = '6LfQdbUcAAAAAKlswDduHfQigwf8q546DgpJTDI3';

const iOS = (navigator.userAgent.includes("Mac") && "ontouchend" in document);

const QRCode = () => {
	const { inputRef } = useQRCode<HTMLCanvasElement>({
		text: window.location.href.split('?qr=true')[0],
		options: {
			level: 'M',
			margin: 7,
			scale: 1,
			width: 400,
			color: {
				dark: '#222',
				light: '#fff',
			},
		},
	});
	return <div className="qrcode"><canvas ref={inputRef} /></div>;
}

function Landing(props: RouteComponentProps) {

	const campaignName = props.location.pathname.split('/')[1]

	const INITIAL_LOCATION_INFO: LocationInfo = {
		available: true,
		checked: false,
		denied: false,
		softAgreed: false,
		coords: null,
	}

	const [error, setError] = useState<string>('');
	const [requirements, setRequirements] = useState<Requirements | null>(null);
	const [requirementsMet, setRequirementsMet] = useState<boolean>(false);
	const [fp, setFP] = useState<any>();
	const [locationInfo, setLocationInfo] = useState<LocationInfo>(INITIAL_LOCATION_INFO);

	useEffect(() => {
		if (window.location.href.indexOf('?qr=true') > -1) {
			return
		}

		(async () => {

			try {
				let response = await fetch(`${API_HOST}api/requirements?campaign_name=${campaignName}`);
				if (!response.ok) {
					const text = await response.text()
					if (text) {
						return setError(text);
					}
					setError(`${response.status}: ${response.statusText}`);
				} else {

					const fp = await fpLoadPromise
					setFP(await fp.get())

					setRequirementsMet(true)

					const reqs: Requirements = await response.json();
					if (reqs.recaptcha) {
						handleRecaptcha(() => setRequirements({ ...reqs }));
					} else {
						setRequirements({ ...reqs });
					}


				}
			} catch (e: any) {
				console.log('fetch error: ', e)
				setError('We encountered an error. Please try again.');
			}



		})();
	}, [campaignName]);


	/// Final Step requirementsMet === true

	async function getRedirectUrl() {

		const { visitorId } = fp

		console.log(visitorId)

		const res = await fetch(`${API_HOST}api/validate`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				campaignName,
				location: locationInfo,
				bypass: 'lms',//new URL(window.location.href).searchParams.get('bypass'),
				visitorId,
			})
		})
		if (res.status === 400) {
			return setError(await res.text())
		}
		const { redirectUrl } = await res.json()
		window.location.href = redirectUrl
	}

	useEffect(() => {
		if (requirementsMet && fp) {
			console.log('requirementsMet: ', requirementsMet)
			getRedirectUrl()
		}
	}, [requirementsMet, fp])

	/// location

	function posSuccess(pos: GeolocationPosition) {
		var { latitude, longitude } = pos.coords;
		setLocationInfo({
			...locationInfo,
			checked: true,
			softAgreed: true,
			coords: { latitude, longitude },
		})
		setRequirementsMet(true); // TODO: fill out validation logic after HoL to include socials
	}

	function posError(err: GeolocationPositionError) {
		setLocationInfo({
			...locationInfo,
			denied: true,
		});
	}

	const checkLocation = () => {
		const posOptions: PositionOptions = {
			enableHighAccuracy: true,
			timeout: 5000,
			maximumAge: 0,
		};
		navigator.geolocation.getCurrentPosition(posSuccess, posError, posOptions)
	}

	/// handles when location is false and goes to getRedirectUrl

	useEffect(() => { // location handler

		if (requirements && !requirements.location) {
			setRequirementsMet(true);
			return
		}

		const handlePermissionsFromResult = (result: PermissionStatus) => {
			if (result.state === "granted") {
				navigator.geolocation.getCurrentPosition(posSuccess);
			} else if (result.state === "prompt") {
				if (locationInfo.softAgreed) { // only prompt after user has soft agreed
					checkLocation()
				} else {
					setLocationInfo({
						...locationInfo,
						checked: true,
					})
				}
			} else if (result.state === "denied") {
				setLocationInfo({
					...locationInfo,
					checked: true,
					denied: true,
				})
			}
		}

		if (requirements?.location) {
			// TODO: recaptcha if necessary, before location flow
			if (navigator.geolocation && navigator.permissions) {
				navigator.permissions
					.query({ name: "geolocation" })
					.then(function (result) {
						console.log('result: ', result)
						handlePermissionsFromResult(result);
						result.onchange = function () {
							console.log('permissions changed: ', result.state);
							handlePermissionsFromResult(result);
						};
					});
			} else {
				setLocationInfo({
					...locationInfo,
					available: iOS || false,
					checked: iOS,
					softAgreed: !iOS
				})
			}
		}
	}, [requirements, locationInfo.softAgreed]);

	/// recaptcha

	const handleRecaptcha = async (onSuccess: () => void) => {
		const loadScriptByURL = (id: string, url: string, callback: () => void) => {
			const exists = document.getElementById(id);

			if (!exists) {
				var script = document.createElement("script");
				script.type = "text/javascript";
				script.src = url;
				script.id = id;
				script.onload = function () {
					if (callback) callback();
				};
				document.body.appendChild(script);
			}

			if (exists && callback) callback();
		}

		loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
			window.grecaptcha.ready(() => {
				window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
					fetch(`${API_HOST}api/recaptcha`, {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({ token })
					})
						.then(res => res.json() as Promise<ReCaptchaResponse>)
						.then(data => {
							console.log('verifcation res: ', data)
							if (!data.success) {
								// TODO: adjust UI to reflect error, rather than just setError (placeholder)
								setError('Recaptcha verification failed')
							} else {
								onSuccess();
							}
						})
						.catch(e => {
							console.log('fetch error: ', e);
							setError('Oops... error verifying recaptcha') // TODO: update this to better UI
						})
				});
			})
		});
	}

	const Header = () => {

		if (campaignName.indexOf('sakura') > -1) {
			return <header className="white">
				<img src={SatoriLogo} />
			</header>
		}

		return <header>
			<img src={Logo} />
		</header>
	}

	if (error) return (
		<div>
			<Header />
			<div className="App">
				<p>{error}</p>
			</div>
		</div>
	)

	if (window.location.href.indexOf('?qr=true') > -1) {
		return <>
			<header className="white">
				<img src={SatoriLogo} />
			</header>
			<QRCode />
		</>
	}

	if (requirements) return ( // requirements have been successfully fetched

		<>
			<Header />

			<div className="App">

				{
					requirements?.location
						? !locationInfo.available
							? <div>
								<p className="centered">We are asking for your location in order to confirm that you are in Lobkowicz Palace viewing this NFT Exhibition.</p>
								<p className="centered">It looks like we can't get it on your current browser. Please try opening this link in a different browser (e.g. Safari or Chrome)</p>
							</div>
							: locationInfo.checked // location is required
								? locationInfo.denied // we've checked for locations permissions
									? <div>
										<p>We are asking for your location in order to confirm that you are in Lobkowicz Palace viewing this NFT Exhibition. This is a one-time authorization and we will not save this data nor use it outside of this application.</p>
										<p>Please turn on your location (GPS) and allow your web browser to use your location.</p>
										<p>Additional instructions:</p>
										<p className="centered"><a href="https://support.apple.com/en-ca/HT207092" target="_blank">Enable location on iOS</a></p>
										<p className="centered"><a href="https://support.google.com/chrome/answer/142065" target="_blank">Enable location on Android</a></p>
									</div>
									: !locationInfo.softAgreed
										? <div className="column"> {/* user hasn't yet agreed to share location */}
											<p>We are asking for your location in order to confirm that you are in Lobkowicz Palace viewing this NFT Exhibition. This is a one-time authorization and we will not save this data nor use it outside of this application.</p>
											<p>Please turn on your location (GPS) and allow your web browser to use your location.</p>
											<p>Additional instructions:</p>
											<p className="centered"><a href="https://support.apple.com/en-ca/HT207092" target="_blank">Enable location on iOS</a></p>
											<p className="centered"><a href="https://support.google.com/chrome/answer/142065" target="_blank">Enable location on Android</a></p>

											<p>After you have enabled location, please press the button below:</p>
											<button onClick={() => iOS ? checkLocation() : setLocationInfo({ ...locationInfo, softAgreed: true })}>Share my location</button>
										</div>
										: !locationInfo.coords
											? <div className="centered">Fetching your location...</div> // user has agreed to share location, but we don't have coords yet
											: <div className="redirecting">Redirecting</div> // user has agreed to share location, and we have coords. LFG!
								: <div className="centered">Checking location permissions</div> // location is required, but hasn't finished being checked yet
						: <div className="redirecting">Redirecting</div> // we don't need location. LFG!
				}
			</div>
		</>
	);

	return null;
};

export default Landing;
